import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as Datepicker from 'vuejs-datepicker';

@Component({
  components: {
    'datepicker': Datepicker.default,
  }
})
export default class DatepickerComponent extends Vue {
    onfocus: boolean = false;
    selectedDate: string = '';
    
    @Prop()
    date!: '';
    
    @Prop()
    openDate!: '';


    @Watch('date', {immediate: true ,deep: true})
    dateChange(e: any) {
      this.selectedDate = e;
    }

    @Prop()
    id!: '';

    @Prop()
    fromDate!: '';

    @Prop()
    toDate!: '';

    @Prop()
    inputClass!: '';

    @Prop()
    styleTop!: boolean;

    dateValidator() {
      this.$emit('onInput');
    }

    keyUp() {
      this.$emit('onKeyUp');
    }

    dateChanged(e: any) {
      this.$emit('onDateChange', e);
    }

}